/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   header: "Apps",
  // },
  // {
  //   url: "/apps/email",
  //   name: "Email",
  //   slug: "email",
  //   icon: "MailIcon",
  //   tag: "new",
  //   tagColor: "primary",
  // },
  // {
  //   url: "/apps/chat",
  //   name: "Chat",
  //   slug: "chat",
  //   icon: "MessageSquareIcon",
  // },
  // {
  //   url: "/apps/todo",
  //   name: "Todo",
  //   slug: "todo",
  //   icon: "CheckSquareIcon",
  // },
  {
    header: "Support",
  },
  {
    url: 'https://www.baidu.com',
    name: "Documentation",
    icon: "BookOpenIcon",
    slug: "external",
    target: "_blank"
  },
]
